<template>
  <ModalContainer @cancel="cancel">
    <div class="bg-white shadow sm:rounded-lg">
      <div class="px-4 py-5 sm:px-6">
        <div class="border-b border-gray-200 pb-5 flex items-center">
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            {{
              dealer
                ? $t("dealers.add_user_to_dealer")
                : $t("dealers.add_user_to_customer")
            }}
          </h3>
          <font-awesome-icon
            icon="times"
            class="text-22 mt-1 ml-10 hover:cursor-pointer"
            @click="cancel()"
          />
        </div>

        <div class="mt-5 sm:flex sm:items-end flex-col">
          <div class="max-w-xs w-full mb-5">
            <div v-if="!users.length">
              <p>
                {{ $t("dealers.no_available_users") }}
              </p>
            </div>
            <div v-else>
              <div class="flex flex-row items-center justify-between">
                <label>{{ $t("customers.users") }}</label>
                <font-awesome-icon
                  v-tooltip="{
                    content: `<div class='flex flex-col z-100'>
                    <span>${$t('dealers.add_existing_user_warning')}</span>
                  </div>`,
                    boundary: 'window',
                    html: true,
                  }"
                  icon="fa-circle-info"
                  class="text-gray-500 text-15"
                />
              </div>
              <SelectorField v-model="selected">
                <option
                  v-for="(user, index) in users"
                  :key="user.id"
                  :value="JSON.stringify(user)"
                  :selected="index === 0"
                >
                  {{ getUserOptionName(user) }}
                </option>
              </SelectorField>
            </div>
          </div>
        </div>

        <div class="border-t border-gray-200 pt-6">
          <div class="flex flex-row justify-end">
            <Button
              button-type="primary"
              :loading="loading"
              :title="$t('dealers.add')"
              @clickAction="addUser()"
            />
          </div>
        </div>
      </div>
    </div>
  </ModalContainer>
</template>

<script>
import ModalContainer from "./../../ModalContainer";
import { RepositoryFactory } from "@/repository/RepositoryFactory";
import SelectorField from "../../../form/SelectorField";
import Button from "../../../form/Button";
const usersRepository = RepositoryFactory.get("user");
import { errorHandlingAndMessage } from "@/services/ErrorService";
import { showNotification } from "@/services/notificationService";

export default {
  name: "AddUserModal",
  components: {
    ModalContainer,
    Button,
    SelectorField,
  },
  props: {
    dealer: {
      type: Number,
      default: null,
    },
    customer: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      users: [],
      selected: null,
    };
  },
  created() {
    usersRepository
      .getAllUsers()
      .then((result) => {
        if (this.dealer !== null) {
          this.users = result.data.data.filter((val) => {
            return val.dealer?.data.id !== this.dealer;
          });
        }
        if (this.customer !== null) {
          this.users = result.data.data.filter((val) => {
            return val.customer?.data.id !== this.customer;
          });
        }
      })
      .catch((err) => {
        errorHandlingAndMessage(
          err,
          this.$t("notifications.something_went_wrong_receiving_users"),
        );
      });
  },
  methods: {
    addUser() {
      const user = JSON.parse(this.selected);
      if (user) {
        user.dealer_id = this.dealer;
        user.customer_id = this.customer;
        usersRepository
          .updateUser(user)
          .then(() => {
            showNotification(
              this.$t("notifications.user_added_to_model", {
                model:
                  this.dealer !== null
                    ? this.$t("users.dealer").toLowerCase()
                    : this.$t("users.customer").toLowerCase(),
              }),
              this.$t("notifications.the_user_is_successfully_added_to_model", {
                model:
                  this.dealer !== null
                    ? this.$t("users.dealer").toLowerCase()
                    : this.$t("users.customer").toLowerCase(),
              }),
              "success",
            );
            this.$emit("close");
            this.$emit("update");
          })
          .catch((err) => {
            errorHandlingAndMessage(
              err,
              this.$t("notifications.there_is_no_user_selected"),
            );
            this.$emit("cancel");
          });
      } else {
      }
    },

    getUserOptionName(user) {
      let dealerOrCustomerName = user.dealer
        ? " (" + this.$t("dealers.dealer") + ": " + user.dealer.data.name + ")"
        : user.customer
          ? " (" +
            this.$t("customers.customer") +
            ": " +
            user.customer.data.name +
            ")"
          : "";

      return user.firstname + " " + user.lastname + dealerOrCustomerName;
    },

    cancel() {
      this.$emit("close");
    },
  },
};
</script>

<style></style>
